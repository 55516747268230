import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import AuthProvider from "contexts/auth";
import CommonProvider from "contexts/common";
import OnboardingProvider from "contexts/onboarding";
import DashboardProvider from "contexts/dashboard";
import PaymentsProvider from "contexts/payments";
import ExpensesProvider from "contexts/expenses";
import ResidentsProvider from "contexts/residents";
import CollectionsProvider from "contexts/collections";
import GlobalWrapper from "layouts/GlobalWrapper";
import RouteWrapper from "layouts/RouteWrapper";
import AuthLayout from "layouts/AuthLayout";
import CommonLayout from "layouts/CommonLayout";
import ScrollToTop from "components/ScrollToTop";
import LazyLoader from "components/LazyLoader";
import "assets/scss/style.scss";

const LoginPage = lazy(() => import("pages/auth/login"));
const RegisterPage = lazy(() => import("pages/auth/register"));
const DashboardPage = lazy(() => import("pages/dashboard"));
const PaymentsPage = lazy(() => import("pages/payments/index"));
const OnboardingPage = lazy(() => import("pages/onboarding/index"));
const CollectionsPage = lazy(() => import("pages/collections/index"));
const PaymentResponsePage = lazy(() => import("pages/payment-response"));
const ResidentsPage = lazy(() => import("pages/residents"));
const ExpensesPage = lazy(() => import("pages/expenses/index"));

const App = () => {
  return (
    <AuthProvider>
      <CommonProvider>
        <OnboardingProvider>
          <DashboardProvider>
            <PaymentsProvider>
              <ExpensesProvider>
                <CollectionsProvider>
                  <ResidentsProvider>
                    <Router>
                      <ScrollToTop />
                      <Suspense fallback={<LazyLoader />}>
                        <GlobalWrapper>
                          <Switch>
                            <RouteWrapper
                              path="/"
                              exact
                              component={DashboardPage}
                              layout={CommonLayout}
                              isPrivate
                            />
                            <RouteWrapper
                              path="/payments"
                              component={PaymentsPage}
                              layout={CommonLayout}
                              isPrivate
                            />
                            <RouteWrapper
                              path="/payment-response"
                              component={PaymentResponsePage}
                              layout={CommonLayout}
                              isPrivate
                            />
                            <RouteWrapper
                              path="/collections"
                              component={CollectionsPage}
                              layout={CommonLayout}
                              isPrivate
                            />
                            <RouteWrapper
                              path="/expenses"
                              component={ExpensesPage}
                              layout={CommonLayout}
                              isPrivate
                            />
                            <RouteWrapper
                              path="/residents"
                              component={ResidentsPage}
                              layout={CommonLayout}
                              isPrivate
                            />
                            <RouteWrapper
                              path="/onboarding"
                              component={OnboardingPage}
                              layout={CommonLayout}
                              isPrivate
                            />
                            <RouteWrapper
                              path="/auth/login"
                              component={LoginPage}
                              layout={AuthLayout}
                            />
                            <RouteWrapper
                              path="/auth/register"
                              component={RegisterPage}
                              layout={AuthLayout}
                            />
                          </Switch>
                        </GlobalWrapper>
                      </Suspense>
                    </Router>
                  </ResidentsProvider>
                </CollectionsProvider>
              </ExpensesProvider>
            </PaymentsProvider>
          </DashboardProvider>
        </OnboardingProvider>
      </CommonProvider>
    </AuthProvider>
  );
};

export default App;
