import { useReducer, createContext } from "react";
import { deleteJSON, getJSON, postJSON, putJSON } from "utils/axios";

const initialState = {
  apartment: {
    data: null,
    isLoading: false,
    error: "",
    isAddingFlat: false,
  },
};

const ACTION_TYPES = {
  TOGGLE_SIDE_MENU: "TOGGLE_SIDE_MENU",
  GET_APARTMENT_REQUEST: "GET_APARTMENT_REQUEST",
  GET_APARTMENT_SUCCESS: "GET_APARTMENT_SUCCESS",
  GET_APARTMENT_FAILURE: "GET_APARTMENT_FAILURE",
  CREATE_APARTMENT_REQUEST: "CREATE_APARTMENT_REQUEST",
  CREATE_APARTMENT_SUCCESS: "CREATE_APARTMENT_SUCCESS",
  CREATE_APARTMENT_FAILURE: "CREATE_APARTMENT_FAILURE",
  UPDATE_APARTMENT_REQUEST: "UPDATE_APARTMENT_REQUEST",
  UPDATE_APARTMENT_SUCCESS: "UPDATE_APARTMENT_SUCCESS",
  UPDATE_APARTMENT_FAILURE: "UPDATE_APARTMENT_FAILURE",
  CREATE_FLAT_REQUEST: "CREATE_FLAT_REQUEST",
  CREATE_FLAT_SUCCESS: "CREATE_FLAT_SUCCESS",
  CREATE_FLAT_FAILURE: "CREATE_FLAT_FAILURE",
  UPDATE_FLAT_REQUEST: "UPDATE_FLAT_REQUEST",
  UPDATE_FLAT_SUCCESS: "UPDATE_FLAT_SUCCESS",
  UPDATE_FLAT_FAILURE: "UPDATE_FLAT_FAILURE",
  REMOVE_FLAT_REQUEST: "REMOVE_FLAT_REQUEST",
  REMOVE_FLAT_SUCCESS: "REMOVE_FLAT_SUCCESS",
  REMOVE_FLAT_FAILURE: "REMOVE_FLAT_FAILURE",
};

export const OnboardingStateContext = createContext();
export const OnboardingDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.TOGGLE_SIDE_MENU:
      return {
        ...state,
        isSideMenuOpen: !state.isSideMenuOpen,
      };
    case ACTION_TYPES.GET_APARTMENT_REQUEST:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          isLoading: true,
          data: null,
          error: "",
        },
      };
    case ACTION_TYPES.GET_APARTMENT_SUCCESS:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          isLoading: false,
          data: {
            ...action.payload.apartment,
          },
          error: "",
        },
      };
    case ACTION_TYPES.GET_APARTMENT_FAILURE:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          isLoading: false,
          data: null,
          error: action.payload.error,
        },
      };
    case ACTION_TYPES.CREATE_APARTMENT_REQUEST:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          data: null,
          error: "",
        },
      };
    case ACTION_TYPES.CREATE_APARTMENT_SUCCESS:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          data: {
            ...action.payload.apartment,
          },
          error: "",
        },
      };
    case ACTION_TYPES.CREATE_APARTMENT_FAILURE:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          data: null,
          error: action.payload.error,
        },
      };
    case ACTION_TYPES.UPDATE_APARTMENT_REQUEST:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          error: "",
        },
      };
    case ACTION_TYPES.UPDATE_APARTMENT_SUCCESS:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          data: {
            ...action.payload.apartment,
          },
          error: "",
        },
      };
    case ACTION_TYPES.UPDATE_APARTMENT_FAILURE:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          error: action.payload.error,
        },
      };
    case ACTION_TYPES.UPDATE_FLAT_REQUEST:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          data: {
            ...state.apartment.data,
            flats: state?.apartment?.data?.flats?.map((f) => {
              if (f._id === action.payload.flatId) {
                return {
                  ...f,
                  isUpdating: true,
                };
              }
              return f;
            }),
          },
        },
      };
    case ACTION_TYPES.UPDATE_FLAT_SUCCESS:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          data: {
            ...state.apartment.data,
            flats: state.apartment.data.flats.map((f) => {
              if (f._id === action.payload.flatId) {
                return {
                  ...f,
                  isUpdating: false,
                  flatNo: action.payload.flatNo,
                };
              }
              return f;
            }),
          },
        },
      };
    case ACTION_TYPES.UPDATE_FLAT_FAILURE:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          data: {
            ...state.apartment.data,
            flats: state?.apartment?.data?.flats?.map((f) => {
              if (f._id === action.payload.flatId) {
                return {
                  ...f,
                  isUpdating: false,
                  error: action.payload.error,
                };
              }
              return f;
            }),
          },
        },
      };

    case ACTION_TYPES.REMOVE_FLAT_REQUEST:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          data: {
            ...state.apartment.data,
            flats: state?.apartment?.data.flats.map((f) => {
              if (f._id === action.payload.flatId) {
                return {
                  ...f,
                  isRemoving: true,
                };
              }
              return f;
            }),
          },
        },
      };
    case ACTION_TYPES.REMOVE_FLAT_SUCCESS:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          data: {
            ...state.apartment.data,
            flats: state.apartment.data.flats.filter(
              (f) => f._id !== action.payload.flatId
            ),
          },
        },
      };
    case ACTION_TYPES.REMOVE_FLAT_FAILURE:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          data: {
            ...state.apartment.data,
            flats: [...state.apartment.data.flats].map((f) => {
              if (f._id === action.payload.flatId) {
                return {
                  ...f,
                  isRemoving: false,
                  error: action.payload.error,
                };
              }
              return f;
            }),
          },
        },
      };

    case ACTION_TYPES.CREATE_FLAT_REQUEST:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          isAddingFlat: true,
        },
      };
    case ACTION_TYPES.CREATE_FLAT_SUCCESS:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          data: {
            ...state.apartment.data,
            flats: [...state.apartment.data.flats, action.payload.flat],
          },
          isAddingFlat: false,
        },
      };
    case ACTION_TYPES.CREATE_FLAT_FAILURE:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          isAddingFlat: false,
        },
      };

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

const OnboardingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <OnboardingDispatchContext.Provider value={dispatch}>
      <OnboardingStateContext.Provider value={state}>
        {children}
      </OnboardingStateContext.Provider>
    </OnboardingDispatchContext.Provider>
  );
};

export const getApartmentDetails = async (dispatch, apartmentId) => {
  dispatch({
    type: ACTION_TYPES.GET_APARTMENT_REQUEST,
  });

  return getJSON(
    `${process.env.REACT_APP_API_BASE_URL}/apartment/${apartmentId}`
  )
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.GET_APARTMENT_SUCCESS,
        payload: {
          apartment: response.data,
        },
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: ACTION_TYPES.GET_APARTMENT_FAILURE,
        payload: {
          error: `${error}`,
        },
      });
      if (error) {
        throw new Error(error);
      }
    });
};

export const createApartment = async (dispatch, values) => {
  dispatch({
    type: ACTION_TYPES.CREATE_APARTMENT_REQUEST,
  });

  return postJSON(`${process.env.REACT_APP_API_BASE_URL}/apartment`, values)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.CREATE_APARTMENT_SUCCESS,
        payload: {
          apartment: response.data,
        },
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: ACTION_TYPES.CREATE_APARTMENT_FAILURE,
        payload: {
          error: `${error}`,
        },
      });
      if (error) {
        throw new Error(error);
      }
    });
};

export const editApartment = async (dispatch, apartmentId, values) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_APARTMENT_REQUEST,
  });

  return putJSON(
    `${process.env.REACT_APP_API_BASE_URL}/apartment/${apartmentId}`,
    values
  )
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_APARTMENT_SUCCESS,
        payload: {
          apartment: response.data,
        },
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_APARTMENT_FAILURE,
        payload: {
          error: `${error}`,
        },
      });
      if (error) {
        throw new Error(error);
      }
    });
};

export const createFlat = async (dispatch, values) => {
  dispatch({
    type: ACTION_TYPES.CREATE_FLAT_REQUEST,
  });

  return postJSON(`${process.env.REACT_APP_API_BASE_URL}/flat`, values)
    .then((response) => {
      console.log();
      dispatch({
        type: ACTION_TYPES.CREATE_FLAT_SUCCESS,
        payload: {
          flat: {
            _id: response.data._id,
            flatNo: response.data.flatNo,
          },
        },
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: ACTION_TYPES.CREATE_FLAT_FAILURE,
        payload: {
          error: `${error}`,
        },
      });
      if (error) {
        throw new Error(error);
      }
    });
};

export const editFlat = async (dispatch, flatId, values) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_FLAT_REQUEST,
    payload: {
      flatId,
    },
  });

  return putJSON(`${process.env.REACT_APP_API_BASE_URL}/flat/${flatId}`, values)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_FLAT_SUCCESS,
        payload: {
          flatId,
          flatNo: values?.flatNo,
        },
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_FLAT_FAILURE,
        payload: {
          flatId,
          error: `${error}`,
        },
      });
      if (error) {
        throw new Error(error);
      }
    });
};

export const removeFlat = async (dispatch, flatId) => {
  dispatch({
    type: ACTION_TYPES.REMOVE_FLAT_REQUEST,
    payload: {
      flatId,
    },
  });

  return deleteJSON(`${process.env.REACT_APP_API_BASE_URL}/flat/${flatId}`)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.REMOVE_FLAT_SUCCESS,
        payload: {
          flatId,
        },
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: ACTION_TYPES.REMOVE_FLAT_FAILURE,
        payload: {
          flatId,
          error: `${error}`,
        },
      });
      if (error) {
        throw new Error(error);
      }
    });
};

export default OnboardingProvider;
