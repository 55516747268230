import { useContext } from "react";
import { AuthStateContext } from "contexts/auth";
const Footer = () => {
  const { apartment } = useContext(AuthStateContext);
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <p>
        <span>&copy;</span>
        <span> {currentYear} </span>
        <span>{apartment?.name ? `${apartment.name} | Powered by ` : ""}</span>
        <a href="https://residy.app">Residy</a>
      </p>
    </footer>
  );
};

export default Footer;
